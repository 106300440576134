.PhoneInputCountry {
  font-size: 43px;
  justify-content: center;
}

.PhoneInputInput {
  font-family: "Montserrat", "sans-serif";
  padding: 5px;
  font-size: 18px;
  border: 1px solid #878787;
  border-radius: 10px;
  text-align: center;
}

.PhoneInputInput:focus {
  outline: none;
  border: 3px solid #c8e9a0;
  transition: 0.15s ease-in-out;
}

.PhoneInputInput::placeholder {
  font-size: 13px;
}
