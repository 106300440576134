* {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
p {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
}

.loading-container {
  display: grid;
  place-content: center;
  height: 350px;
  width: 300px;

  .loading-spinner {
    border: 11px solid;
    border-color: #c8e9a0 transparent #c8e9a0 transparent;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    animation: spinning 1s linear infinite;
  }
}

.loading-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 50%;
  .loading-quote-text {
    animation: pulsing 1.2s linear infinite;
    background: #c8e9a0;
    height: 30px;
    border-radius: 10px;
    width: 475px;
  }
  .loading-author-text {
    animation: pulsing 1.2s linear infinite;
    background: #c8e9a0;
    height: 40px;
    border-radius: 10px;
    width: 70%;
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulsing {
  0% {
    border-radius: 10px;
    background-color: #c8e9a0;
  }
  100% {
    border-radius: 10px;
    background-color: #a9c883;
  }
}
