.quotes-cycler-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 350px;

  .quotes-cycler-quote-author-image-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;

    .quotes-cycler-author-image {
      height: 400px;
      width: auto;
      transform: translate(-10px, 25px);
    }

    .quotes-cycler-quote-author-group {
      .quotes-cycler-quote {
        &::before {
          content: '"';
        }

        &::after {
          content: '"';
        }

        text-align: center;
        font-size: 32px;

        font-family: "Roboto Condensed";
        color: #ffff;
        border-radius: 10px;
        padding: 8px;
      }

      .quotes-cycler-quote-author {
        display: flex;
        margin: 8px 0 0 0;
        font-size: 35px;
        letter-spacing: -2px;
        color: #ffff;
        border-radius: 10px;
        padding: 5px;
        justify-content: center;

        .quotes-cycler-author-first-name {
          margin: 0 9px 0 0;
        }
        .quotes-cycler-author-last-name {
          margin: 0;
        }
      }
    }
    .quotes-cycler-social-share {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;

      button {
        width: 63px;
        height: 63px;
      }

      svg {
        &:hover {
          cursor: pointer;
          transform: scale(1.1);
          transition: 0.2s ease-in-out;
        }
      }
    }
  }
}
