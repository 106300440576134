.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
}

.post-modal-container {
  position: fixed;
  z-index: 2;

  .post-modal {
    background: #fff;
    width: 62%;
    height: 100%;
    top: 0;
    right: 0;
    position: fixed;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, -100%);

    .post-modal-close-button {
      position: absolute;
      transform: translateX(140px);
      top: 20px;
      border: 1.3px solid #878787;
      color: #878787;
      border-radius: 8px;
      font-size: 17px;
      font-family: "Montserrat", "sans-serif";
      background-color: none;
      background: none;

      padding: 10px;

      &:hover {
        color: #ffff;
        border: 1.3px solid #c8e9a0;
        cursor: pointer;
        background-color: #c8e9a0;
        transition: 0.2s ease-in-out;
      }
    }
  }
}
