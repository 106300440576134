.privacy-policy-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  scroll-behavior: smooth;
  overflow: scroll;
  padding: 15px;

  .privacy-policy-heading-button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .privacy-policy-heading {
    color: #fe5e41;
  }

  .privacy-policy-subheading {
    color: #7b7b7b;
  }

  button {
    height: 40px;
    width: 110px;
    border: 1.3px solid #878787;
    color: #878787;
    border-radius: 8px;
    font-size: 17px;
    font-family: "Montserrat", "sans-serif";
    background: none;
    align-self: center;

    padding: 10px;

    &:hover {
      color: #ffff;
      cursor: pointer;
      border: 1.3px solid #c8e9a0;
      background-color: #c8e9a0;
      transition: 0.2s ease-in-out;
    }
  }
  ul {
    list-style: none;
  }
}
