.main-hero-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .main-message-carousel-container {
    .main-message-frame {
      overflow: hidden;

      .main-message-center {
        .main-message-carousel {
          display: flex;
          font-size: 25px;
          line-height: 29px;
          align-items: center;
          justify-content: center;
          margin: 0 0 30px 0;

          .main-message-pretext {
            margin: 0 8px 0 0;
          }

          .main-message-change-outer {
            overflow: hidden;
            height: 27px;

            .main-message-change-inner {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              animation: rotate 8s ease-in-out infinite;

              p {
                background: rgb(200, 233, 160);
                background: -moz-linear-gradient(
                  241deg,
                  rgba(200, 233, 160, 1) 6%,
                  rgba(254, 94, 65, 1) 100%
                );
                background: -webkit-linear-gradient(
                  241deg,
                  rgba(200, 233, 160, 1) 6%,
                  rgba(254, 94, 65, 1) 100%
                );
                background: linear-gradient(
                  241deg,
                  rgba(200, 233, 160, 1) 6%,
                  rgba(254, 94, 65, 1) 100%
                );
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c8e9a0",endColorstr="#fe5e41",GradientType=1);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }

              .main-message-action-word {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}

.main-layout-content {
  display: flex;
  flex-direction: column;
  place-items: center;

  font-family: "Montserrat", "sans-serif";
}

.main-action-buttons-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  button {
    height: 50px;
    border: 1.3px solid #878787;
    color: #878787;
    border-radius: 8px;
    font-size: 17px;
    font-family: "Montserrat", "sans-serif";
    background-color: none;
    background: none;

    padding: 10px;

    &:hover {
      color: #ffff;
      border: 1.3px solid #c8e9a0;
      cursor: pointer;
      background-color: #c8e9a0;
      transition: 0.2s ease-in-out;
    }
  }

  .main-action-buttons-null {
    display: flex;
    align-items: center;
    width: 45%;
    justify-content: space-evenly;
    margin: 20px 0 0 0;

    .main-action-refresh-group {
      font-size: 17px;
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        font-size: 68px;
        &:hover {
          color: #c8e9a0;
          transform: scale(1.2);
          transition: 0.1s ease-in-out;
          cursor: pointer;
          animation: spin 1s linear infinite;
        }
      }

      p {
        font-family: "Roboto Condensed";
      }
    }
  }
  .main-action-buttons-signedin {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    .main-action-buttons-first-group {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 70%;
    }
    .main-action-buttons-second-group {
      display: flex;
      align-items: center;
      justify-content: center;

      .main-action-refresh-group {
        font-size: 17px;
        display: flex;
        flex-direction: column;
        align-items: center;

        svg {
          font-size: 68px;

          &:hover {
            color: #c8e9a0;
            transform: scale(1.2);
            transition: 0.1s ease-in-out;
            cursor: pointer;
            animation: spin 2s ease infinite;
          }
        }

        p {
          font-family: "Roboto Condensed";
        }
      }
      .main-action-social-share-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        .main-action-share-text {
          font-size: 17px;
          font-family: "Roboto Condensed";
        }
        .main-action-share-buttons {
          display: flex;
          width: 100%;
          justify-content: space-evenly;
          align-items: center;

          svg {
            &:hover {
              transform: scale(1.2);
              transition: 0.1s ease-in-out;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@keyframes rotate {
  0%,
  24% {
    transform: translateY(0);
  }

  25%,
  49% {
    transform: translateY(25px * -1.5);
  }

  50%,
  74% {
    transform: translateY(25px * -3);
  }

  75%,
  99% {
    transform: translateY(25px * -4.5);
  }

  100% {
    transform: translateY(25px * -6);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
