.rs-panel {
  width: 80%;
  filter: drop-shadow(1px 4px 7px rgb(111, 111, 111));
  background: rgb(200, 233, 160);
  background: -moz-linear-gradient(
    315deg,
    rgba(200, 233, 160, 1) 10%,
    rgba(254, 94, 65, 1) 76%
  );
  background: -webkit-linear-gradient(
    315deg,
    rgba(200, 233, 160, 1) 10%,
    rgba(254, 94, 65, 1) 76%
  );
  background: linear-gradient(
    315deg,
    rgba(200, 233, 160, 1) 10%,
    rgba(254, 94, 65, 1) 76%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c8e9a0",endColorstr="#fe5e41",GradientType=1);
}

.rs-panel-body {
  padding: 5px;
}

.rs-panel-bordered {
  border: none;
}
