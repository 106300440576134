@media only screen and (max-width: 420px) {
  .web-layout {
    display: grid !important;
    grid-template-columns: 15% 85% !important;
  }
  //main page
  .main-hero-container {
    justify-content: space-between !important;
    .main-message-carousel-container {
      margin: 10px 0 0 0 !important;
      .main-message-frame {
        .main-message-center {
          .main-message-carousel {
            display: flex !important;
            flex-direction: column !important;
            align-items: flex-start !important;
            font-size: 21px !important;
            margin: 0 0 0 33px !important;
          }
        }
      }
    }
    .main-action-buttons-container {
      .main-action-buttons-null {
        margin: 0;
        width: 100% !important;

        button {
          font-size: inherit !important;
        }

        .main-action-refresh-group {
          font-size: 13px !important;
          svg {
            font-size: 45px !important;
          }
        }
      }

      .main-action-buttons-signedin {
        height: 125px !important;
        width: 100% !important;
        margin: 0 !important;

        .main-action-buttons-first-group {
          display: flex;
          flex-direction: column !important;
          width: auto !important;

          button {
            display: flex !important;
            align-items: center !important;
            height: 35px !important;
            font-size: 15px !important;
          }
        }

        .main-action-refresh-group {
          font-size: 16px !important;
          svg {
            font-size: 55px !important;
          }
        }
      }
    }
  }

  .quotes-cycler-container {
    height: 420px !important;
    .quotes-cycler-quote-author-image-container {
      flex-direction: column-reverse !important;
      justify-content: center !important;

      img {
        display: none;
      }

      .quotes-cycler-quote-author-group {
        margin: 25px 0 0 0 !important;
        .quotes-cycler-quote {
          font-size: 22px !important;
        }
        .quotes-cycler-quote-author {
          font-size: 25px !important;
          margin: 0 !important;
        }
      }

      .quotes-cycler-social-share {
        flex-direction: row !important;
        width: 100% !important;
        height: 9.5% !important;

        button {
          height: 40px !important;
          width: 40px !important;

          svg {
            height: 40px !important;
            width: 40px !important;
          }
        }
      }
    }
  }

  .footer {
    .footer-container {
      width: 91% !important;
      .footer-links {
        font-size: 12px !important;
      }
      .footer-copyright {
        font-size: 10px !important;
      }
    }
  }

  //auth page
  .auth-form-container {
    align-items: center !important;
    h1 {
      font-size: 24px !important;
      line-height: 25px !important;
    }

    .auth-phone-number-group {
      height: 100px !important;
      .PhoneInput {
        flex-direction: column !important;
        height: 100% !important;

        .PhoneInputCountry {
          margin: 0 0 8px 0 !important;
        }

        .PhoneInputInput {
          width: 100% !important;
        }
      }
    }

    #back-button {
      margin: 30px 0 0 0 !important;
    }
  }

  //privacy policy & terms
  .privacy-policy-content {
    .privacy-policy-heading-button-group {
      width: 100% !important;
      .privacy-policy-heading {
        font-size: 22px !important;
        line-height: 30px !important;
      }
    }

    .privacy-policy-heading,
    .privacy-policy-subheading {
      font-size: 22px !important;
      line-height: 30px !important;
    }
  }

  .terms-content {
    .terms-heading-button-group {
      width: 100% !important;
      .terms-heading {
        font-size: 22px !important;
        line-height: 30px !important;
      }
    }

    .terms-heading,
    .terms-subheading {
      font-size: 22px !important;
      line-height: 30px !important;
    }
  }

  //new quote modal
  .post-modal {
    width: 100% !important;

    .post-modal-close-button {
      transform: translateX(95px) !important;
      font-size: 15px !important;
    }

    .new-quote-form-container {
      .new-quote-form {
        .first-last-name-group {
          flex-direction: column !important;
          align-items: center !important;
          height: 150px !important;
        }
        .quote-input-group {
          textarea {
            font-size: 15px !important;
          }
        }
        .new-quote-action-buttons {
          display: flex;
          flex-direction: column !important;
          align-items: center !important;
          margin: 0 !important;
          height: 120px !important;
          justify-content: space-around !important;

          .post-quote-button {
            font-size: 15px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) and (max-width: 1024px) {
  .main-action-buttons-container {
    button {
      height: 40px !important;
      font-size: 15px !important;
    }
    .main-action-buttons-signedin {
      margin: 12px 0 0 0 !important;
      width: 90% !important;

      .main-action-refresh-group {
        font-size: 15px !important;
        svg {
          font-size: 45px !important;
        }
      }
    }
  }
  .quotes-cycler-quote-author-image-container {
    padding: 8px !important;
    .quotes-cycler-author-group {
      .quotes-cycler-quote {
        font-size: 30px !important;
      }
    }

    .quotes-cycler-social-share {
      button {
        height: 55px !important;
        width: 55px !important;

        svg {
          height: 55px !important;
          width: 55px !important;
        }
      }
    }
    img {
      display: none;
    }
  }
}

@media (min-width: 890px) and (max-width: 1024px) {
  .web-layout .web-title-container svg {
    height: 100%;
  }

  .quotes-cycler-container {
    height: 230px !important;
  }

  .footer {
    .footer-container {
      width: 90% !important;
    }
  }
}
