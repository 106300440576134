.new-quote-form-container {
  display: grid;
  grid-template-columns: 61px auto;
  height: 100%;

  .new-quote-form-img-container {
    background-color: #fe5e41;
    height: 100%;
    cursor: default;
    display: flex;

    svg {
      height: 100%;
    }
  }

  .new-quote-form {
    display: flex;
    flex-direction: column;
    place-content: center;

    .new-quote-message {
      margin: 0 0 100px 0;
      text-align: center;
      font-size: 28px;
      font-weight: 500;
    }

    .first-last-name-group {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      margin: 0 0 30px 0;
      text-align: center;

      .first-name,
      .last-name {
        display: flex;

        flex-direction: column;

        input {
          padding: 3px;
          font-size: 15px;
          border: 1px solid #878787;
          border-radius: 10px;
          font-family: "Montserrat", "sans-serif";
          width: 220px;
          text-align: center;

          &:focus {
            outline: none;
            border: 1.3px solid #fe5e41;
          }
        }

        label {
          font-weight: 300;
        }
      }
    }

    .quote-input-group {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: 0 55px 60px 55px;

      textarea {
        padding: 20px 0 20px 0;
        font-size: 16px;
        border: 1px solid #878787;
        border-radius: 7px;
        font-family: "Montserrat", "sans-serif";
        text-align: center;
        max-height: 200px;

        &:focus {
          outline: none;
          border: 1px solid #fe5e41;
        }
      }

      label {
        font-weight: 300;
      }
    }

    .new-quote-action-buttons {
      display: flex;
      justify-content: space-around;
      margin: 0 0 55px 0;

      button {
        border: 1.3px solid #878787;
        color: #878787;
        border-radius: 8px;
        font-size: 17px;
        font-family: "Montserrat", "sans-serif";
        background-color: none;
        background: none;

        padding: 10px;

        &:hover {
          color: #ffff;
          border: 1.3px solid #c8e9a0;
          cursor: pointer;
          background-color: #c8e9a0;
          transition: 0.2s ease-in-out;
        }
      }
    }

    span {
      display: block;
    }
  }

  .sent-banner,
  .invalid-banner {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  .sent-banner {
    color: #7cb03d;
  }

  .invalid-banner {
    color: #ed0e0e;
  }
}
